
import {
  useRoute,
  useRouter
} from 'vue-router'
import http from '@/api/index'
// import getFee from '@/assets/js/filter'
// import download from 'downloadjs'
import https from '../api/config'
import { Toast, Dialog } from 'vant'
import { getUrlParam, isWeixn } from '@/assets/js/util'
import { defineComponent, onMounted, computed, toRefs, ref } from 'vue'
declare let WeixinJSBridge: any
// declare let document: any
export default defineComponent({
  setup () {
    const $route = useRoute()
    const $router = useRouter()
    const orderid = ref<string>('')
    const tinfo = ref<any>({})
    const tlist = ref<any>([])
    const orderinfo = ref<any>({})
    const pageinfo = ref<any>({})
    const iswei = ref<boolean>(true)
    const isdown = ref<boolean>(false)
    const shareshow = ref<boolean>(false)
    const geinfo = async () => {
      const data:any = await http.get('portal/index/check_order', {
        order_sn: orderid.value
      })
      console.log('订单信息 0fw', data.code)
      if (data.code === 0) {
        Toast(data.msg)
        return
      }
      tinfo.value = data.data.more.data
      tlist.value = data.data.more.data.list.map((o:any) => {
        const a = {
          ...o,
          fees: o['fee' + o.id]
        }
        return a
      })
      orderinfo.value = data.data
      // const check = window.localStorage.getItem('check') || ''
      // 判断有下载链接
      if (data.data.pay_time > 0) {
        // window.localStorage.setItem('check', '0')
        console.log('pay me')
        if (iswei.value) {
          shareshow.value = true
        } else {
          // downFile()
          isdown.value = true
        }
      } else {
        isdown.value = false
      }
    }

    // 跳转
    const godetail = (obj:any) => {
      // $router.push('/info?id=' + obj.article_id)
      window.open(window.location.origin + '/#/info?id=' + obj.article_id, '_blank')
      // $router.push('/weninfo?id=' + 6)
    }
    const payme = () => {
      const code = window.localStorage.getItem('mywxcode') || ''
      // const code = getUrlParam('code')
      // 判断有下载链接
      if (orderinfo.value.pay_time > 0) {
        // window.localStorage.setItem('check', '0')
        // if (iswei.value) {
        //   shareshow.value = true
        // } else {
        //   downFile()
        // }
        isdown.value = true
      } else {
        http.get('portal/index/pay', {
          order_sn: orderid.value,
          is_wechat: iswei.value ? 1 : 2,
          pay_type: 'wxpay',
          code: code
        }).then((res:any) => {
          if (res.code === 0) {
            geinfo()
            Toast(res.msg)
          } else if (res.code === 3) {
            Toast(res.msg)
            setTimeout(() => {
              // login()
              Dialog.alert({
                message: '登陆已失效，登陆之后请重试'
              }).then(() => {
                login()
              })
            }, 2000)
          } else {
            if (iswei.value) {
              if (code) {
                // 吊起支付
                pay({
                  appId: res.data.appId,
                  // 公众号名称，由商户传入
                  timeStamp: res.data.timeStamp,
                  // 时间戳，自1970年以来的秒数
                  nonceStr: res.data.nonceStr,
                  // 随机串
                  package: res.data.package,
                  signType: res.data.signType,
                  // 微信签名方式：
                  paySign: res.data.paySign
                }).then((or:any) => {
                  console.log('fucking', or)
                  // 支付成功之后查询订单
                  // window.localStorage.setItem('check', '1')
                  window.localStorage.setItem('mywxcode', '')
                  // Toast('支付chengg 拉起查询')
                  geinfo()
                }).catch((err:any) => {
                  Toast('支付异常' + err)
                })
              } else {
                //  没有code
                Dialog.alert({
                  message: '未登录请登录，登陆之后请重试'
                }).then(() => {
                  login()
                })
              }
            } else {
              console.log('支付信息', res.code)
              if (res.code === 0) {
                Toast(res.msg)
              } else if (res.code === 1) {
                const link = window.location.href
                const url = encodeURIComponent(link)
                console.log('zhifu  lujing', link)
                Dialog.alert({
                  message: '如已经支付点击获取下载'
                }).then(() => {
                  geinfo()
                })
                // window.location.href = res.data + `&redirect_url=${url}`
                setTimeout(() => {
                  window.location.href = res.data + `&redirect_url=${url}`
                }, 500)
              }
              // 为配置 h5 支付 先给提示
              // Toast('请使用微信打开')
              // const link = window.location.href
              // const url = encodeURIComponent(link)
              // window.location.href = 'feifji' + `&redirect_url=${url}`
            }
          }
        }).catch((err:any) => {
          // code失效
          console.log('拉起支付异常', err)
          Toast(err.msg)
        })
      }
    }
    const login = () => {
      const link = window.location.href
      console.log('link', link)
      const appid = 'wx74ed30dfc2a1c804'
      const uri = encodeURIComponent(link)
      const authURL = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${appid}&redirect_uri=${uri}&response_type=code&scope=snsapi_base&state=123#wechat_redirect`
      window.location.href = authURL
    }
    const pay = (config: any) => {
      return new Promise((resolve, reject) => {
        console.log('微信支付参数', config, WeixinJSBridge)
        const onBridgeReady = () => {
          WeixinJSBridge.invoke('getBrandWCPayRequest', config, function (res: any) {
            if (res.err_msg === 'get_brand_wcpay_request:ok') {
              resolve(res)
            } else {
              if (res.err_msg === 'get_brand_wcpay_request:cancel') res.err_msg = '你已取消支付'
              reject(res.err_msg)
            }
          })
        }
        if (typeof WeixinJSBridge === 'undefined') {
          if (document.addEventListener) {
            document.addEventListener('WeixinJSBridgeReady', onBridgeReady, false)
          }
          // else if (document.attachEvent) {
          //   document.attachEvent('WeixinJSBridgeReady', onBridgeReady)
          //   document.attachEvent('onWeixinJSBridgeReady', onBridgeReady)
          // }
        } else {
          onBridgeReady()
        }
      })
    }
    const back = () => {
      $router.push('/form')
    }
    const getpageinfo = async () => {
      const data:any = await http.get('portal/pages/read', {
        id: 8
      })
      if (data.code === 1) {
        pageinfo.value = data.data
      } else {
        Toast(data.msg)
      }
    }
    const downFile = () => {
      // window.open(https.baseUrl.dev + 'upload/example.xlsx')
      // http.down('portal/index/download', {
      //   order_sn: orderid.value
      // }).then((res:any) => {
      //   const data = res
      //   if (!data) {
      //     return
      //   }
      //   const url = createObjectURL(new Blob([data]))
      //   const a = document.createElement('a')
      //   a.style.display = 'none'
      //   a.href = url
      //   // a.setAttribute('download', name + '.xlsx')
      //   a.setAttribute('download', 'Workers Compensation45' + '.xlsx')
      //   document.body.appendChild(a)
      //   a.click() // 执行下载
      //   window.URL.revokeObjectURL(a.href)
      //   document.body.removeChild(a)
      // })
      // http.down('portal/index/download', {
      //   order_sn: orderid.value
      // }).then((res:any) => {
      //   const data = res
      //   if (!data) {
      //     Toast('请重试')
      //     return
      //   }
      //   download(new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8' }), 'WorkersCompensation.xlsx')
      // })
      // download('https://www.lyljyzx.com/api/portal/index/download?order_sn=2022050754995248', 'WorkersCompensation.xlsx')
      const a = document.createElement('a')
      a.style.display = 'none'
      a.href = https.baseUrl.dev + '/portal/index/download?order_sn=' + orderid.value
      a.download = 'Workers Compensation45.xlsx'
      document.head.appendChild(a)
      a.click() // 执行下载
      document.head.removeChild(a)
    }
    const createObjectURL = (file:any) => {
      if (window.webkitURL) {
        return window.webkitURL.createObjectURL(file)
      } else if (window.URL && window.URL.createObjectURL) {
        return window.URL.createObjectURL(file)
      } else {
        return ''
      }
    }
    // 过滤价格
    onMounted(() => {
      // Toast('qilai  jinrururu  jinru' + $route.query.orderid)
      orderid.value = $route.query.orderid + ''
      getpageinfo()
      geinfo()
      console.log('ieieieieie', isWeixn())
      const codeall = window.localStorage.getItem('mywxcode') || ''
      if (isWeixn()) {
        iswei.value = true
        const code = getUrlParam('code')// 获取地址参数code的值
        console.log('window.location.href', window.location.href)
        console.log('window.location.origin + window.location.pathname + window.location.hash', window.location.origin + window.location.pathname + window.location.hash)
        if (code) {
          window.localStorage.setItem('mywxcode', code)
          // 获取 oppid 之后 刷新
          window.location.href = window.location.origin + window.location.pathname + window.location.hash
          // payme()
        } else {
          if (!codeall) {
            login()
          }
        }
      } else {
        // const check = window.localStorage.getItem('check') || ''
        // if (check === '1') {
        //   window.localStorage.setItem('check', '0')
        //   geinfo()
        // }
        iswei.value = false
      }
    })
    return {
      tinfo,
      iswei,
      shareshow,
      pageinfo,
      isdown,
      tlist,
      payme,
      downFile,
      godetail,
      back
    }
  }
})
