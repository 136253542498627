export const getUrlParam = (name: string) => {
  const reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)')
  const url = window.location.href.split('#')[0]
  const search = url.split('?')[1]
  if (search) {
    const r = search.substr(0).match(reg)
    if (r !== null) return unescape(r[2])
    return null
  } else {
    return null
  }
}

export const isWeixn = ():boolean => {
  const ua = navigator.userAgent.toLowerCase()
  const isWeixin = ua.indexOf('micromessenger') !== -1
  if (isWeixin) {
    return true
  } else {
    return false
  }
}
